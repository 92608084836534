window.BMD = window.BMD || {};

BMD.nav = (function($){
  var windowHeight, headerHeight,

  init = function(){
    windowHeight = $(window).height();
    headerHeight = $('header').height();

    window.addEventListener('resize', function(event){
      if($('.si-nav-mobile').is(':visible')) {
        $('.si-nav-mobile').hide();
      }
      if($(window).width() <= 767) {
        $('.header__icn-angle').removeClass('header__icn-angle_up');
        $('.header__list-vertical').removeClass('header__drop-down-menu_visible');
      }
    });

    $(document).on('click', '.si-nav__submenu-trigger', function(ev) {
      var target = $(ev.target);
      if(!target.is('li>a')) { // I'M NOT SORRY AND I WON'T APOLOGISE
        ev.preventDefault();
        var clickedItem = $(this);
        var windowWidth = getWindowWidth();

        spinArrow(clickedItem);

        if(windowWidth <= 767) {
          toggleMobileNav();
        }
        else {
          toggleDesktopSubmenu(clickedItem);
        }
      }
    });
  },

  spinArrow = function(clickedItem) {
    $('.si-nav__submenu-trigger').not(clickedItem).find('.header__icn-angle').removeClass('header__icn-angle_up');
    clickedItem.find('.header__icn-angle').toggleClass('header__icn-angle_up');
  },

  getWindowWidth = function() {
    return $(window).width();
  },

  toggleMobileNav = function(){
    if($('.si-nav-mobile>ul').is(':visible')) {
      $('body').unbind('touchmove');
    }
    else {
      $('body').bind('touchmove', function(e){e.preventDefault()});
    }
    $('body').toggleClass('noscroll');
    $('.si-nav-mobile').toggleClass('si-nav-mobile_visible');
  },

  toggleDesktopSubmenu = function(clickedItem) {
    $('.header__list-vertical').not(clickedItem.find('.header__list-vertical')).each(function() {
      $(this).removeClass('header__drop-down-menu_visible');
    });
    clickedItem.find('.header__list-vertical').toggleClass('header__drop-down-menu_visible');
  };

  return {
    init: init
  }
})(jQuery);
