window.BMD = window.BMD || {};

BMD.signOut = (function($){
  var init = function(){
    $('#signout a').on('click', function(event) {
      window.localStorage.removeItem('jwt');
      return true;
    });
  };

  return {
    init: init
  }
})(jQuery);
