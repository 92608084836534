window.BMD = window.BMD || {};

BMD.signIn = (function($){
  var init = function(){
    setupForms();
    setUpFormSubmissionHandler();
  },

  setupForms = function() {
    var $signIn = $('.header__sign-in');

    $('.header__right').on('click', '.header__signin-form-trigger', function(event) {
      event.preventDefault();
      if($(window).width() <= 414) {
        $signIn.slideToggle();
      } else {
        $signIn.show().toggleClass('header__desktop-signin_visible');
      }
    });
  },

  setUpFormSubmissionHandler = function() {
    $("form.sign-in-form").on('submit', function(event) {
      event.preventDefault();
      var $form = $(this);

      $.ajax({
        url: $form.attr('action'),
        method: $form.attr('method'),
        dataType: 'json',
        data: $form.serialize()
      }).success(function(data) {
        if(data['status'] === 'success' || data['status'] === 'reset') {
          window.location.replace(data['redirect_to']);
        } else {
          var flash = data['flash'];
          var error = flash['error'];

          showErrorBar(error);
          hideWarningBar();
          hideFlashBar();
          $('body').scrollTop(0);
        }
      });
    });
  },

  showErrorBar = function(text){
    var errorBar = $('.sign-in__notification_error');
    $('.sign-in__notification_error p').text(text);
    $(errorBar).css("display", "block");
  },

  hideWarningBar = function(){
    var warningBar = $('.sign-in__notification_warn.notification.notification_warn');
    warningBar.hide();
  };

  hideFlashBar = function(){
    var flashBar = $('.sign-in__notification_flash');
    flashBar.hide();
  };

  return {
    init: init
  }
})(jQuery);
